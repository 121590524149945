import 'babel-polyfill'
import $ from "jquery"
import "magnific-popup"
import ErrorReporting from '/v2/js/modules/error_reporting.js'
import { VideoCelebration } from '/v2/js/modules/video_celebration.js'
import { ARScene } from '/v2/js/modules/ar_scene.js'
import { UserInterface } from '/v2/js/modules/user_interface.js'

const websiteHost = process.env.WEBSITE_HOST
const params = new URLSearchParams(window.location.search)
const giftboxCode = params.get('code')
const arScene = new ARScene(giftboxCode)

window.addEventListener('DOMContentLoaded', async () => {
  $('#faq_link').magnificPopup({
    type:'inline',
    closeBtnInside: false
   });
  // get video celebration data
  const videoCelebration = await new VideoCelebration(websiteHost, giftboxCode).getData()
  if (videoCelebration.hasError()) {
    showError('There was an error fetching your video celebration')
  }
  else if (videoCelebration.isLegacy()) {
    runAway("/index_v1.html?code=" + giftboxCode)
  }
  else if (videoCelebration.isGood()) {
    var userInterface = new UserInterface(videoCelebration.url, videoCelebration.videos, arScene, $.magnificPopup)
    userInterface.setGreeting(videoCelebration.occasion)
    arScene.initialiseAssets(videoCelebration.videos, videoCelebration.portraitUrlBase64)
    arScene.addHeaderImage()
    arScene.addPreviewImages()
    userInterface.start()
  } else {
    showInfo('We could not find any videos for this celebration. Redirecting to your gift box.')
    runAway(videoCelebration.url)
  }
})

function runAway(url) {
  document.location.href = url
  return
}

function showError(message) {
  document.querySelector("#error_message").innerHTML = message
  document.querySelector(".error").classList.remove("hide")
  document.querySelector(".greeting").classList.add("hide")
  document.querySelector(".info").classList.add("hide")
  document.querySelector(".loading").classList.add("hide")
}

function showInfo(message) {
  document.querySelector("#info_message").innerHTML = message
  document.querySelector(".info").classList.remove("hide")
  document.querySelector(".error").classList.add("hide")
  document.querySelector(".greeting").classList.add("hide")
  document.querySelector(".loading").classList.add("hide")
}

window.addEventListener('camera-error', (error) => {
  console.log('camera-error', error)
  ErrorReporting.notify({
    name: 'ARVM camera error',
    params: {code: giftboxCode, error: error}
  })
})

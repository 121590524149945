class VideoCelebration {
  constructor (host, code) {
    this.giftboxCode = code
    this.websiteHost = host
    this.url = `${host}/giftbox/${code}`
    this.occasion = null
    this.videos = null
    this.title = null
    this.error = false
    this.portraitUrlBase64 = null
  }

  async getData () {
    return await fetch(`${this.websiteHost}/api/v1/giftbox/${this.giftboxCode}/json`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
      redirect: 'follow'
    }).then(async (response) => {
      let data = await response.json()
      this.title = data.title
      this.portraitUrlBase64 = data.portraitUrlBase64
      this.videos = data.videos
      this.occasion = data.occasion
      return this
    }).catch((error) => {
      this.error = error
      return this
    });
  }

  isLegacy() {
    let legacy = false
    this.videos.forEach( (video) => {
      if (video.is_legacy) legacy = true
    })
    return legacy
  }
  hasError () {
    return this.error
  }

  isGood () {
    return this.videos && this.videos.length > 0
  }

}

export { VideoCelebration }

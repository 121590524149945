class ARScene {
  constructor (code) {
    this.markerId = '#main-marker'
    this.thumbnailsId = '#thumbnails'
    this.assets = null
    this.imageSize = 0.2
    this.headerImageWidth = 2
    this.headerImageHeight = 1.2
    this.maxColumns = 0
    this.numRows = 0
    this.numVideos = 0
    AFRAME.registerComponent('main-marker', {
      init: function () {}
    })
  }

  initialiseAssets (videos, portraitUrlBase64) {
    this.assets = document.querySelector('#assets')
    this.maxColumns = (this.headerImageWidth / this.imageSize) * 2
    this.numRows = 15
    this.numVideos = videos.length
    // video thumbnails
    videos.forEach((video, i) => {
      let img = document.createElement('img')
      img.id = 'asset-' + i
      img.crossOrigin = 'anonymous'
      img.src = this.imageSrc(video)
      this.assets.appendChild(img)
    })

    // header image
    let img = document.createElement('img')
    img.id = 'asset-header'
    img.src = 'data:image/png;base64,' + portraitUrlBase64
    this.assets.appendChild(img)
  }

  addPreviewImages () {
    var count = 0
    var index = 0
    for (var row = 0; row < this.numRows; row++) {
      for (var column = 0; column < this.maxColumns; column++) {
        var thumb = document.createElement('a-plane')
        thumb.classList.add('thumbnail')
        thumb.classList.add('thumbnail-' + index)
        thumb.setAttribute('shadow', true)
        thumb.setAttribute('src', '#asset-' + index)
        thumb.setAttribute('scale', this.imageSize + ' ' + this.imageSize)
        let x = (-0.5 * this.maxColumns * this.imageSize) + (column * this.imageSize) + (this.imageSize / 2)
        let y = (0.5 * this.numRows * this.imageSize) + (-1 * ((row * this.imageSize) + (this.imageSize / 2)))
        thumb.setAttribute('position', x + ' -1 ' + y)
        thumb.setAttribute('rotation', '-90 0 0')
        thumb.setAttribute('material', 'opacity: 0.8; transparent: true')
        document.querySelector(this.markerId).appendChild(thumb)
        count++
        index = count % this.numVideos
      }
    }
  }

  removePreviewImages () {
    document.querySelectorAll("thumbnail").remove()
  }

  addHeaderImage () {
    var plane = document.createElement('a-plane')
    plane.classList.add('plane-portrait')
    plane.setAttribute('shadow', true)
    plane.setAttribute('id', 'profile')
    plane.setAttribute('src', '#asset-header')
    plane.setAttribute('scale', this.headerImageWidth + ' ' + this.headerImageHeight)
    plane.setAttribute('position', '0 1 0')
    plane.setAttribute('rotation', '-90 0 0')
    document.querySelector(this.markerId).appendChild(plane)
  }

  imageSrc (video) {
    return video.thumbnail_base64 ?
            'data:image/png;base64,' + video.thumbnail_base64
            :
            video.thumbnail_url
  }

}

export { ARScene }

import Flickity from 'flickity'
class UserInterface {
  constructor (siteUrl, videos, arScene, magnificPopup) {
    this.videoPlayer = document.getElementById('video-player')
    this.videos = videos
    this.numVideos = videos.length
    this.markerVisible = false
    this.welcomeInstructions = document.querySelector('#welcome')
    this.closeDialog = document.querySelector('.close-dialog')
    this.videoPicker = document.querySelector('#video-picker')
    this.videoPickerContainer = document.querySelector('.video-picker-container')
    this.arScene = arScene
    this.popUp = magnificPopup
    this.currentVideoId = 0
    this.arScene = arScene

    this.videos.forEach((video, id) => {
      let userInterface = this
      let element = document.createElement('div')
      element.innerHTML = '<h3>' + video.title + '</h3><div class="video-play"><img src="play-solid.svg"></div>'
      element.classList.add('carousel-cell')
      element.id = id
      userInterface.videoPicker.appendChild(element)
      element.addEventListener('click', (e) => {
        e.preventDefault()
        userInterface.showVideoPlayer()
        userInterface.popUp.open({
          items: {
            src: '#player-dialog',
            type: 'inline',
            closeBtnInside: false
          },
          callbacks: {
              open: function() {
                userInterface.play(id)
              },
              close: function() {
                userInterface.pause()
                userInterface.reset()
              }
          }
        })
      })
    })

    this.flkty = new Flickity(this.videoPicker, {
      contain: true,
      cellSelector: '.carousel-cell',
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      on: {
        change: function (index) {
          //disable for now
          //this.arScene.sayHi(this.flkty.selectedElement.id)
        }.bind(this)
      }
    })

    document.querySelector(arScene.markerId).addEventListener('markerFound', () => {
      this.start()
    })

    document.querySelector(arScene.markerId).addEventListener('markerLost', () => {
      this.markerVisible = false
      this.showInstructions()
    })

    document.querySelector('#giftbox_link').addEventListener('click', (e) => {
      e.preventDefault()
      document.location.href = siteUrl
    })

    this.videoPlayer.addEventListener('ended', (event) => {
      //this.hideVideoPlayer()
      let index = (this.currentVideoId + 1) % this.videos.length
      this.play(index)
    })

  }

  start() {
    if (this.markerIsVisible()) {
      this.markerVisible = true
      this.showControls()
    }
  }

  markerIsVisible() {
    return this.markerVisible || document.querySelector(this.arScene.markerId).object3D.visible == true
  }

  play (index) {
    this.currentVideoId = index
    this.setVideoPlayerSrc(index)
    this.videoPlayer.play()
  }

  pause () {
    this.videoPlayer.pause()
  }

  setVideoPlayerSrc (index) {
    this.videoPlayer.src = this.videos[index].compressed_video_url
    //this.videoPlayer.src = this.videos[index].raw_video_url
    this.videoPlayer.load()
  }

  showControls () {
    this.welcomeInstructions.classList.remove('active')
    this.videoPickerContainer.classList.add('active')
  }

  showInstructions () {
    this.videoPickerContainer.classList.remove('active')
    this.welcomeInstructions.classList.add('active')
  }

  showVideoPlayer () {
    this.welcomeInstructions.classList.remove('active')
    this.videoPickerContainer.classList.remove('active')
  }

  hideVideoPlayer () {
    this.popUp.close()
    this.reset()
  }

  reset () {
    if (this.markerVisible) {
      this.showControls()
    } else {
      this.showInstructions()
    }
  }

  setGreeting(videoCelebration) {
    //possible occasions include: anniversary, baby_shower, birthday, graduation,
    // humble_heroes, kids, retirement, workplace, get_well,
    // teacher_appreciation, thank_you, wedding, memorial
    // and if occasion not available (ARVM) then product codes: birthday, romance, map, milestone
    var greeting
    switch(videoCelebration.occasion) {
      case "anniversary":
      greeting = "🎉&nbsp;Happy Anniversary&nbsp;🎉"
        break;
      case "birthday", "milestone":
      greeting = "🎉&nbsp;Happy Birthday&nbsp;🎉"
        break;
      case "get_well":
      greeting = "Get well soon"
        break;
      case "thank_you":
      greeting = "Thank you!"
        break;
      case "memorial":
      greeting = "Welcome"
        break;
      default:
      greeting = "🎉&nbsp;Congratulations&nbsp;🎉"
          break;
    }
    document.querySelector("#occasion_greeting").innerHTML = greeting
    document.querySelector(".error").classList.add("hide")
    document.querySelector(".greeting").classList.remove("hide")
    document.querySelector(".loading").classList.add("hide")
    this.showInstructions()
  }
}
export { UserInterface }
